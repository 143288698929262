import "core-js/stable";
import "regenerator-runtime/runtime";
import 'whatwg-fetch';
import 'es6-promise/auto';
import React from "react";
import ReactDOM from "react-dom";

import { Provider } from 'react-redux';
import { store } from './helpers';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom"; //, Redirect

import "bootstrap/dist/css/bootstrap.css";
import "assets/css/now-ui-dashboard.css";
import "assets/css/demo.css";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";

window.BASE_URL = '/clintouch';
const hist = createBrowserHistory({basename: window.BASE_URL});

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist} >
      <Switch>
        <Route
          path="/admin"
          render={props => {
            return <AdminLayout {...props} />;
          }}
        />
        <Route 
          path="/auth"
          render={props => {
            return <AuthLayout {...props} />;
          }}
        />
        <Redirect to="/auth/login" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);

//        <Redirect to="/auth/login" />