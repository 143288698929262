import { genericConstants } from '../constants';
import { wallpapersService} from '../services'; 
import { alertActions } from '.';

export const wallpapersActions = {
    getAll
};

function getAll() {
    return dispatch => {
        dispatch(request());

        //console.log("wallpapersService.getAll");
        wallpapersService.getAllWallpapers()
            .then(
                response => {
                    //console.log("wallpapersActions.response", response.data);
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.WALLPAPERS_REQUEST } }
    function success(items) { return { type: genericConstants.WALLPAPERS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.WALLPAPERS_FAILURE, error } }
}