import React from "react";
import { connect } from 'react-redux';

import { Redirect } from "react-router-dom";

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Container,
  Col,
  FormGroup,
  Input,
  Button
} from "reactstrap";

import { mfaService } from '../../services/data.service';
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import avatar from "assets/img/logo-big.jpg";
import bgImage from "assets/img/bg13.jpg";
import { authActions } from '../../actions';

class MFATokenLogin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username:"",
      password:"",
      mfacode:"",
      alert: null,
    }
  }
  componentDidMount() {
    //console.log("MFATokenLogin componentDidMount");
    document.body.classList.add("lock-page");
  }
  componentWillUnmount() {
    //console.log("MFATokenLogin componentWillUnmount");
    document.body.classList.remove("lock-page");
  }

  async doLoginAttempt() {
    //const { username, password } = this.props.location.state;
    const { username, password, mfacode } = this.state;
    
    //console.log("MFATokenLogin doLoginAttempt Username/password/mfacode: ", username, "/", password, "/", mfacode);

    if (username && password && mfacode >= 5) {
      this.props.login(username, password, mfacode);
    } else {
      //console.log("MFATokenLogin doLoginAttempt Missing credentials");
      this.setState({
        alert: (
          <SweetAlert
            warning
            title="Wrong Code!"
            onConfirm={() => this.hideAlert()}
            confirmBtnBsStyle="error"
          >
            Please enter a valid code.
          </SweetAlert>
        )
      })
    }
    //console.log("handleSubmit usertoken ", window.localStorage.getItem("usertoken"));
  }


  doRequestCode() {
    //const { username, password } = this.props.location.state;
    const { username, password } = this.state;

    //console.log("MFATokenLogin doRequestCode Username/password: ", this.state.username, "/", password);

    mfaService.requestNewMFACode(username, password)
      .then(() => {
        this.setState({
          alert: (
            <SweetAlert
              warning
              title="New Code Requested!"
              onConfirm={() => this.hideAlert()}
              confirmBtnBsStyle="success"
            >
              An email with a new code has been sent to the associated email address.
            </SweetAlert>
          )
        });
      })
      .catch(error => {
        console.error("Error requesting new code:", error);
      });
  }

  hideAlert() {
    this.setState({ alert: null });
  }

  render() {
    const { error, loggedIn } = this.props;

    //console.log("MFATokenLogin Error/LoggedIn: ", error, "/", loggedIn);

    if(loggedIn){
      //this.props.history.push('/admin/dashboard');
      return <Redirect to='/admin/dashboard'/> //Go to dashboard
    }
    
    var wrong_MFA_Code = null;
    if(error && error.toString().includes("WRONG_MFA")){
      wrong_MFA_Code = (
        <SweetAlert
          warning
          title="Wrong Code!"
          onConfirm={() => this.hideAlert()}
          confirmBtnBsStyle="error"
        >
          Please check the code you entered or ask for a new one.
        </SweetAlert>
      );
      this.props.error = null;
    }

    try {
      const { username, password } = this.props.location.state;

      if(!this.state.username || !this.state.password){
        this.setState({ username: username });
        this.setState({ password: password });
      }

      //console.log("MFATokenLogin Username/password: ", username, "/", password);
    } catch (e) {
      //console.log("MFATokenLogin Error getting username/password: ", e, this.state.username, "/", this.state.password);
    }

    /*
    if(this.state.username && this.state.password && this.state.mfacode){
      console.log("MFATokenLogin All data set!!!!!: ", this.state.username, "/", this.state.password, "/", this.state.mfacode);
    }*/

    var fakeURL = "#";

    return (
      <>{wrong_MFA_Code}
        {this.state.alert}
        <div className="content">
          <div className="lock-page">
            <Container>
              <Col lg={4} md={8} xs={12} className="mr-auto ml-auto">
                <Card className="card-lock text-center">
                  <CardHeader>
                    <img src={avatar} alt="avatar-img" style={{ backgroundColor: 'white', borderRadius: 35 }} />
                  </CardHeader>
                  <CardBody>
                    <CardTitle tag="h3">2-Step Authentication</CardTitle>
                    <h6 style={{ fontWeight: 400, textTransform: 'none' }}>Insert the code you've received by email.</h6>
                    <br />
                    <FormGroup>
                      <Input type="text" placeholder="Enter the code here..."
                        onChange={(e) => this.setState({ mfacode: e.target.value })} />
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" size="lg" className="btn-round"
                      onClick={this.doLoginAttempt.bind(this)}>
                      Login
                    </Button>
                    <br /><br />
                    <a href={fakeURL} onClick={this.doRequestCode.bind(this)}>Request another code by email</a>
                    <br />
                  </CardFooter>
                </Card>
              </Col>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        />
      </>
    );
  }
}

function mapState(state) {
  const { loggedIn, error } = state.authentication;
  return { loggedIn, error };
}

const actionCreators = {
  login: authActions.login,
  logout: authActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(MFATokenLogin);
export { connectedLoginPage as MFATokenLogin };

