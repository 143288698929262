
import React from "react";
import { connect } from 'react-redux';

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";

import nowLogo from "assets/img/logo_transparent_medium.png";
import bgImage from "assets/img/blurred-image-1.jpg";

import { authActions } from '../../actions';

class LoginPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      usernameFocus: false,
      passwordFocus: false,
      errorLogin: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ errorLogin: '' });
  }

  componentDidMount() {
    //console.log("LOGINPAGE componentDidMount");
    document.body.classList.add("login-page");
    
    this.setState({
      username: '',
      password: ''
    });
    this.props.needMFA = null;  }

  componentWillMount() {
    //console.log("LOGINPAGE componentWillMount");

    this.setState({
      username: '',
      password: ''
    });
    this.props.needMFA = null;
  }

  componentWillUnmount() {
    //console.log("LOGINPAGE componentWillUnmount");
    document.body.classList.remove("login-page");
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { username, password } = this.state;
    
    if (username && password) {
      this.props.login(username, password, null);
    } else {
      this.setState({ errorLogin: 'missing-credentials' });
    }

    //console.log("handleSubmit usertoken ", window.localStorage.getItem("usertoken"));
  }

  render() {
    const { needMFA, error } = this.props; //loggedIn
    const { username, password } = this.state;

    //console.log("LOGINPAGE Need MFA/Error/username/password: ", needMFA, "/", error, "/", username, "/", password);
    
    if(username && password && needMFA){
      //console.log("LOGINPAGE redirect to MFA Page ");
      
      this.props.needMFA = false;
      this.props.error = null;

      this.props.history.replace({ //push
        pathname: '/auth/mfa-token-login', //'/auth/password-help', //'/auth/mfa-token-login',
        state: { username, password }
      });
      //return <Redirect to='/mfa-token-login'/> //Ask for MFA Code
    }

    const badCredentialsError = () => {
      var errorLoginTxt = '';
      //console.log("badCredentialsError: ", this.state.errorLogin, '/', error, '/',  error && error.toString().includes("INVALID_CREDENTIALS"));

      if(error && error.toString().includes("INVALID_CREDENTIALS")){
        errorLoginTxt = 'Invalid Username/Password';
      } else if(error && error.toString().includes("USER_BLOCKED")){
        errorLoginTxt = 'User is blocked. Please contact your administrator.';
      } else {
        errorLoginTxt = '';
      }

      if (error) { //this.state.errorLogin !== '') {
          return (
              <>
                  <p className="login-error">
                      {errorLoginTxt}
                  </p>
              </>);
      }
  }
/* width:140 ,height:140, JLIZASO Was in "logo-container"*/
/* style={{ width: '140px', height: '130px' }} Was in the Image */
    return (
      <>
        <div className="content">
          <div className="login-page">
            <Container>
              <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                <Form onSubmit={this.handleSubmit}>
                  <Card className="card-login card-plain">
                    <CardHeader>
                      <div className="logo-container" style={{marginBottom:0}}>
                        <img src={nowLogo} alt="clintouch-logo"  />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <InputGroup className={"no-border form-control-lg " + (this.state.usernameFocus ? "input-group-focus" : "")}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input name="username" type="text" placeholder="Username"
                          value={username} 
                          onChange={this.handleChange}
                          onFocus={e => this.setState({ usernameFocus: true })}
                          onBlur={e => this.setState({ usernameFocus: false })}
                        />
                      </InputGroup>
                      <InputGroup
                        className={"no-border form-control-lg " + (this.state.passwordFocus ? "input-group-focus" : "")}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input name = "password" type="password" placeholder="Password"
                          value={password} 
                          onChange={this.handleChange}
                          onFocus={e => this.setState({ passwordFocus: true })}
                          onBlur={e => this.setState({ passwordFocus: false })}
                        />
                      </InputGroup>
                      {badCredentialsError()}
                    </CardBody>
                    <CardFooter>
                      <Button block color="primary" size="lg" className="mb-3 btn-round" type="submit">
                        Get Started
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        />
      </>
    );
  }
}

function mapState(state) {
  const { loggedIn, needMFA, error } = state.authentication;
  return { loggedIn, needMFA, error };
}

const actionCreators = {
  login: authActions.login,
  logout: authActions.logout
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };
