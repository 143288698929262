import { genericConstants } from '../constants';
import { participantsService, encountersService } from '../services'; 
import { alertActions } from '.';

export const participantsActions = {
    getParticipant,
    getAll,
    getAllMy,
    getSummary,
    getParticipantEncounters
};

function getAll() {
    return dispatch => {
        dispatch(request());

        participantsService.getAllParticipants()
            .then(
                response => {
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.PARTICIPANTS_REQUEST } }
    function success(items) { return { type: genericConstants.PARTICIPANTS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.PARTICIPANTS_FAILURE, error } }
}

function getAllMy(pId) {
    return dispatch => {
        dispatch(request());

        participantsService.getMyParticipants(pId)
            .then(
                response => {
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.PARTICIPANTS_REQUEST } }
    function success(items) { return { type: genericConstants.PARTICIPANTS_SUCCESS, items } }
    function failure(error) { return { type: genericConstants.PARTICIPANTS_FAILURE, error } }
}

function getSummary(id ) {
    return dispatch => {
        dispatch(request());

        participantsService.getParticipantSummary(id)
            .then(
                response => {
                    let date = new Date(); 
                    response.timestamp =  date.getTime();
                    response.encounters.forEach(function (el) {
                        el.details = []
                        el.observations.forEach(function (ob) {
                            if (ob) { //DAWNP: ob && ob.valueNumeric > 0 - Accept Answer value = 0
                                if (ob.question && ob.question.concept) {
                                    el.details.push({
                                        question: ob.question.text,
                                        symptom: ob.question.concept.name,
                                        answer: ob.valueNumeric,
                                        order: ob.question.id
                                    })
                                }
                            };
                        });
                        el.modalNotice = false;
                    });

                    dispatch(success(response));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };
 
    function request() { return { type: genericConstants.PARTICIPANTS_SUMMARY_REQUEST } }
    function success(dataObj) { return { type: genericConstants.PARTICIPANTS_SUMMARY_SUCCESS, dataObj } }
    function failure(error) { return { type: genericConstants.PARTICIPANTS_SUMMARY_FAILURE, error } }
}

function getParticipant(id ) {
    return dispatch => {
        dispatch(request());

        participantsService.getParticipant(id)
            .then(
                response => {
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.PARTICIPANT_REQUEST } }
    function success(dataObj) { return { type: genericConstants.PARTICIPANT_SUCCESS, dataObj } }
    function failure(error) { return { type: genericConstants.PARTICIPANT_FAILURE, error } }
}

function getParticipantEncounters(participantId) {
    return dispatch => {
        dispatch(request());

        encountersService.getEncountersByParticipantId(participantId)
            .then(
                response => {
                    console.log(response);
                    dispatch(success(response.data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: genericConstants.PARTICIPANTS_ENCOUNTER_REQUEST } }
    function success(dataObj) { return { type: genericConstants.PARTICIPANTS_ENCOUNTER_SUCCESS, dataObj } }
    function failure(error) { return { type: genericConstants.PARTICIPANTS_ENCOUNTER_FAILURE, error } }
}