/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, CardFooter, Row, Col, FormGroup, Form, Input, Button } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { isStudyStarted } from '../../helpers';
import { wallpapersActions } from '../../actions';
import { wallpapersService } from '../../services';

class WallpapersEdit extends Component {
    constructor(props) {
        super(props);
        var pid = props.match.params.pid;
        var readOnly = isStudyStarted();

        this.state = {
            readOnly: readOnly,
            alert: null,
            show: false,
            id: pid,
            data: [],
            isAdd: false,
            validation: {
                title: 'has-danger',
                image: 'has-danger',
            },
            title: '',
            image: ''
        };

        if (props.location.state && props.location.state.theWallpaper) {
            var p = props.location.state.theWallpaper;
            if (p && p !== null) {
                this.state.title = p.title;
                this.state.image = "data:image/jpeg;base64," +p.image;
                //console.log("this.state.title: ", this.state.title);
                this.state.validation = {
                    title: '',
                    image: ''
                }
            } else {
                this.props.history.goBack();
            }
        } else {
            this.state.isAdd = true;
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

        //console.log("name, value: ", name, value)
        switch (name) {
            case 'title':
                if (value.length >= 3) {
                    this.state.validation["title"] = "has-success";
                } else {
                    this.state.validation["title"] = "has-danger";
                }
                break;
            case 'image':
                if (value != null) {
                    this.state.validation["image"] = "has-success";
                } else {
                    this.state.validation["image"] = "has-danger";
                }
                break;

            default:
                break;
        }
    }

    hideAlert() {
        this.setState({ alert: null });
    }

    hideAlert1() {
        this.setState({ alert: null });
        this.props.history.goBack();
    }

    handleFileChange(event) {
        const {target} = event;
        const {files} = target;

        if (files && files[0]) {
          var file = files[0];
          var reader = new FileReader();

          reader.onloadstart = () => this.setState({loading: true});
    
          reader.onloadend = event => {
            var srcData = event.target.result; // <--- data: base64
            var img = new Image();
          
            img.onload = event => {
                var height = img.height;
                var width = img.width;

                if (srcData.length < 2500000 && height>width) {
                    this.state.validation["image"] = "has-success";
                } else {
                    this.state.validation["image"] = "has-danger";
                }

                this.setState({
                    file: file,
                    image: srcData,
                    loading: false
                });
            }
           
            img.src = URL.createObjectURL(file);

            //console.log("file", file);
            //console.log("URL.createObjectURL(file)", URL.createObjectURL(file));

          };
       
          reader.readAsDataURL(file);
        } 
    }

    saveWallpaper() {
        if (this.state.validation.title === "has-danger" ||
            this.state.validation.image === "has-danger" ) {
            this.setState({
                alert: (
                    <SweetAlert
                        error
                        style={{ display: "block", marginTop: "100px" }}
                        title="Something when wrong!"
                        onConfirm={() => this.hideAlert()}
                        onCancel={() => this.hideAlert()}
                        confirmBtnBsStyle="danger"
                    >
                        Please review the errors and try again.
                  </SweetAlert>
                )
            });
        } else {
            var theWallpaper = {};

            if (this.state.isAdd !== true) {
                theWallpaper = this.props.location.state.theWallpaper;
            } else {
                
            }
            theWallpaper.title = this.state.title;
            theWallpaper.image = this.state.image;
            theWallpaper.file = this.state.file;
        
            //console.log("theWallpaper", theWallpaper);
           //console.log("this.state.isAdd", this.state.isAdd);

            if (this.state.isAdd !== true) {
                wallpapersService.saveWallpaper(theWallpaper, theWallpaper.id).then(r => {
                    this.setState({
                        alert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "100px" }}
                                title="Wallpaper Saved"
                                onConfirm={() => this.hideAlert1()}
                                onCancel={() => this.hideAlert1()}
                                confirmBtnBsStyle="info"
                            >
                            </SweetAlert>
                        )
                    });

                }
                );
            } else {
                wallpapersService.addWallpaper(theWallpaper).then(r => {
                    //console.log("theWallpaper added");
                    this.setState({
                        alert: (
                            <SweetAlert
                                success
                                style={{ display: "block", marginTop: "100px" }}
                                title="Wallpaper Saved"
                                onConfirm={() => this.hideAlert1()}
                                onCancel={() => this.hideAlert1()}
                                confirmBtnBsStyle="info"
                            >
                            </SweetAlert>
                        )
                    });
                }
                );
            }
        }
    }

    render() {
        //const { items, wallpapersLoading } = this.props;
        const titleValidationError = () => {
            if (this.state.validation.title === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                The Wallpaper needs a valid title (More than 3 characters).
                        </small>
                        </p>
                    </>);
            }
        }

        const imageValidationError = () => {
            if (this.state.validation.image === "has-danger") {
                return (
                    <>
                        <p className="text-danger">
                            <small>
                                You have to select a a valid image to upload, max size is 2 MB and it has to be portrait orientation.
                        </small>
                        </p>
                    </>);
            }
        }

        var renderWallpaperName = <Col xs={12} md={5}>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <CardTitle tag="h5">Title</CardTitle>
                                            {titleValidationError()}
                                            <FormGroup className={"has-label " + this.state.validation.title}>
                                                <Input type="text" name="title"
                                                    value={this.state.title}
                                                    readOnly = {this.state.readOnly}
                                                    onChange={this.handleChange.bind(this)} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>;

        var title = 'Create new wallpaper';
        if (this.state.id > 0) {
            renderWallpaperName = "";
            if (this.state.readOnly){
                title = `View wallpaper ${this.state.title}`
            } else {
                title = `Edit wallpaper ${this.state.title}`
            }
        }

        var buttonChooseFile = <Col xs={12} md={6}>
                                    <input className="btn-fill" color="warning" 
                                                            type="file"
                                                            accept="image/jpeg"
                                                            capture="camera"
                                                            onChange={this.handleFileChange.bind(this)}
                                                        />
                                </Col>;
        var buttonSubmit = <Button className="btn-fill pull-right" color="success" type="submit" onClick={this.saveWallpaper.bind(this)}> Submit </Button>;

        if(isStudyStarted()){
            buttonSubmit = "";
            buttonChooseFile = "";
        }

        return (

            <>
                {this.state.alert}
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <Card>
                                        <CardTitle tag="h4" className="pull-left" >{title}</CardTitle>
                                        {buttonSubmit}
                                        {"  "}
                                        <Button className="btn-fill pull-right" color="danger" type="submit"
                                            onClick={() => {
                                                this.props.history.goBack();
                                            }}>
                                            Cancel
                                        </Button>
                                    </Card>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Row>
                                            {renderWallpaperName}
                                            <Col xs={12} md={5}>
                                                <CardTitle tag="h5">Image</CardTitle>
                                                <Row>
                                                    {buttonChooseFile}
                                                    <Col xs={12} md={7}>
                                                        {imageValidationError()}
                                                        <FormGroup className={"has-label " + this.state.validation.image}>
                                                            <img name="image"
                                                                src={this.state.image}
                                                                alt={this.state.title}
                                                                onChange={this.handleChange.bind(this)}
                                                                value={this.state.image} />
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>

                                <CardFooter className="pull-right">
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

function mapState(state) {
    const { items, wallpapersLoading } = state.wallpapers;
    return { items, wallpapersLoading };
}

const actionCreators = {
    getAll: wallpapersActions.getAll
};

const connectedWallpapersEdit = connect(mapState, actionCreators)(WallpapersEdit);
export { connectedWallpapersEdit as WallpapersEdit }; 