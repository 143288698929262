
import React from "react";
import { connect } from 'react-redux';
import { authActions } from '../../actions';

import { Redirect } from "react-router-dom";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import {
  Collapse,
  Navbar,
  NavbarToggler,
  // NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container
} from "reactstrap";

class AdminNavbar extends React.Component {

  sidebarToggle = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent"
    }; 
    
  }


  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent"
      });
    } else {
      this.setState({
        color: "white"
      });
    }
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
  dropdownToggle = e => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  };
  openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.sidebarToggle.current.classList.toggle("toggled");
  };
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "white"
      });
    } else {
      this.setState({
        color: "transparent"
      });
    }
  };
  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.sidebarToggle.current.classList.toggle("toggled");
    }
  }

  handleLogout(e) {
    // console.log(e);
    this.props.logout();
  }

  render() {

    const { loggedIn } = this.props; 
    var userN = window.localStorage.getItem("login");
    if(loggedIn === false ){
      console.log("JLIZASO - LOGOUT Button: " + loggedIn + " " + userN);
      return  <Redirect to="/auth/login"/>
    }

    return (
      <Navbar
        color={
          window.location.href.indexOf("full-screen-maps") !== -1
            ? "white"
            : this.state.color
        }
        expand="lg"
        className={
          window.location.href.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute "
            : "navbar-absolute " +
            (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref={this.sidebarToggle}
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            {/* <NavbarBrand href="/">{this.props.brandText}</NavbarBrand> */}
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end"
          >

            <Nav navbar>

              <Dropdown
                nav
                isOpen={this.state.dropdownOpen}
                toggle={e => this.dropdownToggle(e)}
              >
                <DropdownToggle caret nav>
                  <i className="now-ui-icons users_single-02" />
                  <p>
                    <span className="d-md-block">{userN}</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu right>
                  {/* <DropdownItem tag="a">User Profile (User: {userN})</DropdownItem> */}
                  <DropdownItem tag="a" onClick={() => this.handleLogout()}>Logout</DropdownItem>
                </DropdownMenu>
              </Dropdown>

            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

function mapState(state) {
  const { loggedIn } = state.authentication;
  return { loggedIn };
}

AdminNavbar.defaultProps = {
  brandText: "Default Brand Text"
};

AdminNavbar.propTypes = {
  // string for the page name
  brandText: PropTypes.string
};

const actionCreators = {
  logout: authActions.logout
};

const connectedAdminNavbar = connect(mapState, actionCreators)(AdminNavbar);
export { connectedAdminNavbar as AdminNavbar };
