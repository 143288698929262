/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Form } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

class TermsnConditions extends Component {
    constructor(props) {
        super(props);

    }

    render() {

        const styles = {
            padding: {
                paddingLeft: "5%",
                paddingRight: "5%"
            }
        };

        return (

            <>
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">CareLoop Health - Terms & Conditions</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Row>
                                            <Col xs={12} md={12}>
                                            <Row style={styles.padding}>
                                                    <Col xs={12} md={12}>
                                                        <p>This is the Terms & Conditions notice of CareLoop Health Ltd (“CareLoop Health Ltd”, “we”, “us”, “our”)  
                                                            <br/>We are company number 13219481 registered in the United Kingdom.
                                                            <br/>Our registered office is at CareLoop Health Ltd, 46 Grafton Street, Manchester M13 9NT. Registered in England.
                                                            <br/>
                                                            <br/>Effective date: 20/01/2022
                                                            <br/>Last reviewed: 20/01/2022
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">1. Introduction</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>1.1</b> The CareLoop Clinician Dashboard was built to work in conjunction with the CareLoop App as a digital tool for symptom collection and self-management for people with serious mental illness.  It is owned and developed by the company CareLoop Health Ltd. By using the Clinician Dashboard, these terms will automatically apply to you – you should make sure therefore that you read them carefully before using the Clinician Dashboard.
                                                        <br/><b>1.2 Contacting us.</b> To find out more about who we are and our role, visit the <a href="https://careloophealth.com/" target="_blank">CareLoop Health website</a>Z. If you think the CareLoop App is faulty, or you wish to contact us, please email us at <a href="mailto:support@careloophealth.com">support@careloophealth.com</a>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">2. When these terms apply</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>2.1</b> These terms and conditions ("terms of use") apply to your use of the CareLoop Clinician Dashboard. Whenever you use any version of the CareLoop Clinican Dashboard, you agree to our terms of use.
                                                        <br/><b>2.2</b> Read these terms of use before using our Clinician Dashboard.
                                                        <br/><b>2.3</b> By agreeing to our terms of use, you are also agreeing to comply with:
                                                        <ul>
                                                            <li>Our Clinician Dashboard Privacy Policy, which may be updated from time to time, and which sets out the terms on which we process any personal data we collect from you.</li>
                                                            <li>Any other separate terms of use, privacy policies, cookies policies and other terms and conditions we may notify you of.</li>
                                                        </ul>
                                                        <br/><b>2.4</b> We may, at any time and in our sole discretion, amend these terms of use for any reason. For example, to comply with law or reflect changes to or new features on the CareLoop Clinician Dashboard. The latest version of our terms of use will be accessible through our website, therefore you are advised to review this page periodically for any changes. These changes are effective immediately after they are posted on our website.
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">3. How to register to use the CareLoop Clinician Dashboard</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>3.1</b> This Clinician Dashboard is provided by CareLoop Health Ltd and is intended for use as part of a research project or implementation study or clinical plan. Please note that the CareLoop Clinician Dashboard and associated App is never intended as a substitute for clinical care.
                                                        <br/><b>3.2</b> The Clinician Dashboard and App should only be used in these contexts for which it has been designed and under the supervision of the end user’s clinical team. It is not intended for public use as is.
                                                        <br/><b>3.3</b> Services and arrangements may differ elsewhere outside the UK. If you access or attempt to access the CareLoop Clinician Dashboard from any location outside the UK, you are responsible for complying with any local laws that apply to you in the country from which you are using the CareLoop Clinician Dashboard.
                                                        <br/><b>3.4</b> Use of the CareLoop Clinician Dashboard is not authorised in any country outside the UK that does not give effect to all provisions of these terms of use, including without limitation the choice of law and jurisdiction clauses.
                                                        <br/><b>3.5</b> We have many measures in place to keep your data safe. But it is important that you also play your part – visit the government's Cyber Aware website for advice on how to do this.
                                                        <br/><b>3.6</b> CareLoop Health Ltd accepts no liability for any loss, direct or indirect, you experience as a result of using the CareLoop Clinician Dashboard.
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">4. Accessing the CareLoop Clinician Dashboard</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>4.1</b> You are responsible for making all arrangements necessary for you to access the CareLoop Clinician Dashboard, including but not limited to an internet connection and an appropriate device ("<b>Device</b>") for access. The connection can be Wi-Fi, or provided by your mobile network provider, but CareLoop Health Ltd cannot take responsibility for the Clinician Dashboard not working at full functionality if you don’t have access to Wi-Fi, and you don’t have any of your data allowance left.
                                                        <br/><b>4.2</b> If you are using the CareLoop Clinician Dashboard outside of an area with Wi-Fi, you should remember that your terms of the agreement with your mobile network provider will still apply. As a result, you may be charged by your mobile provider for the cost of data for the duration of the connection while accessing the Clinician Dashboard, or other third party charges. In using the Clinician Dashboard, you are accepting responsibility for any such charges, including data roaming charges if you use the Clinician Dashboard outside of your home territory (i.e. region or country) without turning off data roaming. If you are not the bill payer for the device on which you are using the Clinician Dashboard, please be aware that we assume that you have received permission from the bill payer.
                                                        <br/><b>4.3</b> We are committed to ensuring that the Clinician Dashboard is as useful and efficient as possible. For that reason, we reserve the right to make changes to the Clinician Dashboard or to charge for its services, at any time and for any reason. We will never charge you for the Clinician Dashboard without making it very clear to you exactly what you are paying for without your prior approval.
                                                        <br/><b>4.4</b> We may suspend, withdraw, discontinue or change all or any part of the CareLoop Clinician Dashboard without notice. We will not be liable to you if for any reason the CareLoop Clinician Dashboard are unavailable at any time or for any period. Unless we tell you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must stop using the Clinician Dashboard, and (if needed) delete it from your device.
                                                        <br/><b>4.5</b> We are giving you and your clinical team the right to access and use the CareLoop Clinician Dashboard. You may not transfer the CareLoop Dashboard to anyone else. If you sell, loan or otherwise dispose of any device on which the CareLoop Clinician Dashboard is accessed, you must remove any login details for the CareLoop Clinician Dashboard from the device beforehand.
                                                        <br/><b>4.6</b> The CareLoop Clinician Dashboard is only intended to be used by you and your clinical team.
                                                        <br/><b>4.7</b> When using the CareLoop Clinician Dashboard you should ensure you are using a secure internet connection, please refer to the government's Cyber Aware website for more guidance.
                                                        <br/><b>4.8</b> In order to operate correctly, the CareLoop Clinician Dashboard requires you to use a device, operating system and browser that comply with certain minimum requirements.
                                                        <br/><b>4.9</b> You are not allowed to copy, or modify the CareLoop Clinician Dashboard, any part of the CareLoop App, or our trademarks in any way. You are not allowed to attempt to extract the source code of the CareLoop Clinician Dashboard or App, and you should not try to translate the Clinician Dashboard or App into other languages, or make derivative versions.
                                                        <br/><b>4.10</b> The CareLoop Clinician Dashboard and App, their derivatives, and all the trademarks, copyright, database rights and other intellectual property rights related to it, belong to CareLoop Health Ltd.
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">5. Updates to the CareLoop Clinician Dashboard</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>5.1</b> From time to time we may update the CareLoop Dashboard to improve performance, enhance functionality, reflect changes to the operating system, or address security issues.</p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">6. Ending your use of CareLoop Clinician Dashboard</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>6.1</b> If your CareLoop account is cancelled or terminated, this may mean you will not be able to login to the CareLoop Clinician Dashboard.
                                                        <br/><b>6.2</b> If you breach any of these terms of use, we may prevent you from accessing the CareLoop Clinician Dashboard and cancel your CareLoop account. If we terminate (or suspend) your access to the CareLoop Clinician Dashboard, we will notify you. If what you have done can be put right, we will tell you and give you a reasonable opportunity to do so.
                                                        <br/><b>6.3</b> If you cancel your CareLoop Clinician Dashboard account or we end your rights to use the CareLoop Clinician Dashboard:
                                                        <ul>
                                                            <li>You must stop all activities authorised by these terms of use, including your use of the CareLoop Clinician Dashboard.</li>
                                                            <li>Where we end your rights to use the CareLoop Clinician Dashboard, you must not seek to create a new account without our prior written consent.</li>
                                                            <li>Any personal data we hold about you will be dealt with in accordance with our data retention policy, which is set out in our <u>privacy policy</u>.</li>
                                                            <li>All rights granted to you under these terms of use shall automatically cease without further notice (although your statutory rights - for example in respect of data protection as described in the privacy policy - are not affected).</li>
                                                            <li>(Where applicable) you must immediately delete or remove all copies of the CareLoop Clinician Dashboard from all devices or other IT equipment in your possession.</li>
                                                            <li>We will cease providing you with access to the CareLoop Clinician Dashboard.</li>
                                                        </ul>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">7. Your right to use the CareLoop Clinician Dashboard</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>7.1</b> We either own or have the right to use for the purposes of providing the CareLoop Clinician Dashboard all intellectual property rights, including rights in copyright, patents, database rights, trademarks and other intellectual property rights <b>(“CareLoop Health Ltd IPR”)</b> in:
                                                        <ul>
                                                            <li>The CareLoop Clinician Dashboard and App.</li>
                                                            <li>Any materials or content of whatever nature available through the CareLoop Clinician Dashboard and App including any information, data, text, images, videos, interactive services, links to products and apps, and other works in any format <b>("Online Content")</b>.</li>
                                                        </ul>
                                                        <br/>Save as expressly permitted in these terms of use, you will need to obtain permission in writing from us or any other owner of any CareLoop Health Ltd IPR (where we are the licensee) before you may use these items in any way. We do not sell the CareLoop Clinician Dashboard, App, Connected Services or any Online Content to you, and we or our licensors remain the owners of such materials at all times.
                                                        <br/><b>7.2 Permission to use CareLoop Health Ltd IPR.</b> As long as you comply with these terms of use, we grant you a personal, worldwide, perpetual, non-exclusive, non-transferable, revocable, limited licence to use the CareLoop Clinician Dashboard. We reserve all other licence rights not expressly permitted under these terms of use.
                                                        <br/><b>7.3</b> Unless permitted by law or under these terms of use, you will:
                                                        <ul>
                                                            <li>Not copy the CareLoop Clinician Dashboard, App or any CareLoop Health Ltd IPR except where such copying is incidental to normal use.</li>
                                                            <li>Not rent, lease, sub-license, loan, translate, merge, adapt, vary or modify the CareLoop Clinician Dashboard, App or any CareLoop Health Ltd IPR.</li>
                                                            <li>Not modify the CareLoop Clinician Dashboard, App or any CareLoop Health Ltd IPR, or permit them to be combined with, or become incorporated in, any other programmes or services.</li>
                                                            <li>Not disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the CareLoop Clinician Dashboard, App or any other CareLoop Health Ltd IPR.</li>
                                                            <li>Comply with all technology control or export laws that apply to the technology used by the CareLoop Clinician Dashboard, App or other any CareLoop Health Ltd IPR.</li>
                                                        </ul>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">8. Prohibited uses</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>8.1</b> You may not:
                                                        <ul>
                                                            <li>Infringe CareLoop Health Ltd IPR in relation to your use of the CareLoop Clinician Dashboard to the extent that such use is not expressly permitted by these terms of use.</li>
                                                            <li>Transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the CareLoop Clinician Dashboard.</li>
                                                            <li>Collect any data from our systems or any other systems or attempt to decipher any transmissions to or from the servers running the CareLoop Clinician Dashboard and App.</li>
                                                            <li>Use the CareLoop Clinician Dashboard on any device or operating system that has been modified outside the operating system vendor supported or warranted configurations.</li>
                                                            <li>Use the CareLoop Clinician Dashboard in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other users.</li>
                                                            <li>Use the CareLoop Clinician Dashboard in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these terms of use, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses or harmful data, into the CareLoop Clinician Dashboard.</li>
                                                            <li>Use the CareLoop Clinician Dashboard in connection with any kind of denial-of-service attack whether in relation to the CareLoop Clinician Dashboard or otherwise. By breaching this provision, you may be committing a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use the CareLoop Clinician Dashboard will cease immediately.</li>
                                                            <li>Use the CareLoop Clinician Dashboard in any way that constitutes improper use or otherwise in a manner not reasonably contemplated by these terms of use.</li>
                                                            <li>Use the CareLoop Clinician Dashboard to knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, spyware or any other harmful programmes or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
                                                        </ul>
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">9. Our liability to you</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>Please read this clause carefully, as it sets out the limits of our liability to you in relation to your use of the CareLoop Clinician Dashboard.</b></p>
                                                        <p><b>9.1</b> The CareLoop Clinician Dashboard may contain technical inaccuracies or typographical errors. We reserve the right at any time and without notice to make changes and improvements to the CareLoop Clinician Dashboard.
                                                        <br/><b>9.2</b> Although we make reasonable efforts to update them, the CareLoop Clinician Dashboard is provided "as is" and, to the extent permitted by law, we make no representations, warranties or guarantees, whether express or implied (including but not limited to the implied warranties of satisfactory quality and fitness for a particular purpose), that the CareLoop Clinician Dashboard is (a) accurate, complete or up-to-date; (b) meet your particular requirements or needs; or (c) access to, or use of, the same will be uninterrupted or completely secure. This is because we are completely reliant on third parties to provide accurate and correct data for the CareLoop Clinician Dashboard.
                                                        <br/><b>9.3</b> If the need arises, we reserve the right to amend, delete, suspend or withdraw all or any part of the CareLoop Clinician Dashboard without notice. We will not be liable if, for any reason, the CareLoop Clinician Dashboard or any parts are unavailable at any time.
                                                        <br/><b>9.4</b> We cannot guarantee that the CareLoop Clinician Dashboard will be uninterrupted or error free, that defects will be corrected, or that the CareLoop Clinician Dashboard or the servers that make them available will be free of viruses or represent the full functionality, accuracy or reliability of the materials. You should use your own virus protection software (and ensure that it is regularly updated) when accessing and using the CareLoop Clinician Dashboard, and you acknowledge that the introduction of threats or viruses may be as a result of circumstances that are not within our control.
                                                        <br/><b>9.5</b> Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, any loss or damage to a device or digital content belonging to you, if you can show that i) this was caused by the CareLoop Clinician Dashboard and ii) we failed to use reasonable skill and care to prevent this, or any other liability that cannot be excluded or limited under English law. It is the responsibility of the clinical team to: i) train the service user in using the CareLoop app, ii) understand how to use, interpret and act on the data provided in the Clinician Dashboard. CareLoop Health Ltd cannot be held responsible if use of the Clinician Dashboard and the data within it are not conducted as described above.
                                                        <br/><b>9.6</b> Subject to clause 9.5 of these terms of use, we will not be liable or responsible to you for:
                                                        <ul>
                                                            <li>Any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your device, computer equipment, computer programmes, data or other proprietary material due to your use of the CareLoop Clinician Dashboard.</li>
                                                            <li>Any loss or damage that was not caused by our breach of these terms of use.</li>
                                                            <li>Any business loss (including but not limited to loss of profits, revenue, contracts, anticipated savings, data, goodwill or wasted expenditure).</li>
                                                            <li>Any indirect or consequential losses that were not foreseeable to both you and us when you commenced using the CareLoop Clinician Dashboard (loss or damage is "foreseeable" if it was an obvious consequence of our breach of these terms of use or if they were contemplated by you and us at the time we entered into the contract created by your use of the CareLoop Clinician Dashboard).</li>
                                                            <li>Any loss or damage arising from an inability to access and/or use the CareLoop Clinician Dashboard in whole or in part.</li>
                                                            <li>Use or reliance on any content available through the CareLoop Clinician Dashboard regardless of the origins of such content unless due to our breach or negligence.</li>
                                                            <li>Any other loss or damage whether arising under tort (including negligence), breach of contract, breach of statutory duty or otherwise.</li>
                                                        </ul>
                                                        <br/><b>9.7</b> This clause 9 does not affect any legal rights you may have as a consumer in relation to defective services or software. Advice about your legal rights is available from your local Citizen's Advice or Trading Standards Office.
                                                        <br/><b>9.8</b> You acknowledge that we have made the CareLoop Clinician Dashboard available to you in reliance upon these terms of use (including the exclusions and limits of liability in this clause 10).
                                                        </p>
                                                    </Col>
                                                </Row>`
                                                <Row style={styles.padding}>
                                                    <CardTitle tag="h5">10. General</CardTitle>
                                                    <Col xs={12} md={12} style={styles.padding}>
                                                        <p><b>10.1</b> We may transfer our rights and obligations under these terms of use to another organisation. We will always tell you in writing if this happens, and we will ensure that the transfer will not affect your rights under these terms of use. You may only transfer your rights or your obligations under these terms of use to another person if we agree in writing.
                                                        <br/><b>10.2</b> Subject to any specific terms detailed on the CareLoop Clinician Dashboard in relation to particular features or materials, these terms of use sets out the entire agreement between you and us in respect of your use of the CareLoop Clinician Dashboard.
                                                        <br/><b>10.3</b> These terms of use do not give rise to any rights for any third party to enforce any term of these terms of use.
                                                        <br/><b>10.4</b> We will not be in breach of any of our obligations under these terms of use (or otherwise liable for any failure or delay in performance) if we are prevented, hindered or delayed in or from performing any of our obligations by any event beyond our reasonable control. The time for performance of our obligations shall be extended accordingly.
                                                        <br/><b>10.5</b> Each of the clauses of these terms of use operates separately. If any part of these terms of use is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and exclusions and limits of liability, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these terms of use shall continue in effect (and if any of the sub-clauses in clause 9.6 of these terms of use are held to be invalid and are struck out, then this shall not impact on any of the remaining sub-clauses).
                                                        <br/><b>10.6</b> Even if we delay in enforcing these terms of use, we can still enforce them later. If we do not insist immediately that you do anything you are required to do under these terms of use, or if we delay in taking steps against you in respect of your breaking these terms of use, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date.
                                                        <br/><b>10.7</b> The laws of England shall apply exclusively to these terms of use and to all matters relating to use of the CareLoop Clinician Dashboard. Any cause of action arising under these terms of use or the use of the CareLoop Clinician Dashboard shall be subject to the exclusive jurisdiction of the courts of England.
                                                        </p>
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}


function mapState(state) {
}

const connectedTermsnConditions = connect(mapState)(TermsnConditions);
export { connectedTermsnConditions as TermsnConditions }; 