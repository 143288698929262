
import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  Container,
  Col,
  FormGroup,
  Input,
  Button
} from "reactstrap";

import { requestResetPass } from '../../services/data.service';
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import avatar from "assets/img/logo-big.jpg";
import bgImage from "assets/img/bg13.jpg";

class PasswordRecovery extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username:"",
      alert: null,
    }
  }
  componentDidMount() {
    document.body.classList.add("lock-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("lock-page");
  }

  doPasswordRecoveryAttempt() {
    if (this.state.username.length >= 5) {
      requestResetPass(this.state.username).then(
        this.setState({
          alert: (
            <SweetAlert
              warning
              title="New Password Requested!"
              onConfirm={() => this.hideAlert()}
              confirmBtnBsStyle="success"
            >
              If you entered a valid username, then Password Reset email has been sent to the associated email address.
            </SweetAlert>
          )
        })
      );
    }
  }

  hideAlert() {
    this.setState({ alert: null });
    this.props.history.push('/auth/login-page');
  }

  render() {
    return (
      <>
        {this.state.alert}
        <div className="content">
          <div className="lock-page">
            <Container>
              <Col lg={4} md={8} xs={12} className="mr-auto ml-auto">
                <Card className="card-lock text-center">
                  <CardHeader>
                    <img src={avatar} alt="avatar-img" style={{ backgroundColor: 'white', borderRadius: 35 }} />
                  </CardHeader>
                  <CardBody>
                    <CardTitle tag="h3">Password Recovery</CardTitle>
                    <h6 style={{ fontWeight: 400, textTransform: 'none' }}>When you complete this form, an email will be sent to the matching address stored in our database.</h6>
                    <h6 style={{ fontWeight: 400, textTransform: 'none' }}>The email will contain a link which you may use to request a new password.</h6>
                    <br />
                    <FormGroup>
                      <Input type="text" placeholder="Enter your username here..."
                        onChange={(e) => this.setState({ username: e.target.value })} />
                    </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Button color="primary" size="lg" className="btn-round"
                      onClick={this.doPasswordRecoveryAttempt.bind(this)}>
                      Recover my Password
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Container>
          </div>
        </div>
        <div
          className="full-page-background"
          style={{ backgroundImage: "url(" + bgImage + ")" }}
        />
      </>
    );
  }
}

export default PasswordRecovery;
