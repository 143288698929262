/* eslint-disable */
import React, { Component } from "react";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Form } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import { overviewStatsActions } from '../../actions';

class OverviewStats extends Component {
    constructor(props) {
        super(props);

        //console.log("Dashboard: ", props.location.pathname);

        this.state = {
            totalNoOfParticipantsWithEncounters: '',
            totalNoOfParticipants: '',
            totalNoOfEncounters: '',
            totalNoOfPartialEncounters: '',
            totalNoOfCompleteEncounters: '',
            totalNoOfMissingEncounters: '',

            lastWeekPartial: '',
            lastWeekComplete: '',
            lastWeekMissing: '',
            lastMonthPartial: '',
            lastMonthComplete: '',
            lastMonthMissing: '',

            amountUsersCompleteWeek: '',
            amountUsersMissingWeek: '',
            amountUsersComplete3Day: '',
            amountUsersMissing3Day: '',
            lastEncounter: '',
            data: []
        };
    }

    componentDidMount() {
        const { getAll, getAllMy, location } = this.props;
    
        // Dispatch the action and wait for it to complete
        if (location.pathname === "/admin/dashboard") {
          getAll()
            .then(response => {
                const overviewStats = response; // Assuming the response object has an 'items' property
                //console.log("DATA RETURNED: ", overviewStats);
                this.setState({
                    overviewStats: overviewStats,
                    totalNoOfParticipantsWithEncounters: overviewStats.totalNoOfParticipantsWithEncounters,
                    totalNoOfParticipants: overviewStats.totalNoOfParticipants,
                    totalNoOfEncounters: overviewStats.totalNoOfEncounters,
                    totalNoOfPartialEncounters: overviewStats.totalNoOfPartialEncounters,
                    totalNoOfCompleteEncounters: overviewStats.totalNoOfCompleteEncounters,
                    totalNoOfMissingEncounters: overviewStats.totalNoOfMissingEncounters,
                    lastWeekPartial: overviewStats.lastWeekPartial,
                    lastWeekComplete: overviewStats.lastWeekComplete,
                    lastWeekMissing: overviewStats.lastWeekMissing,
                    lastMonthPartial: overviewStats.lastMonthPartial,
                    lastMonthComplete: overviewStats.lastMonthComplete,
                    lastMonthMissing: overviewStats.lastMonthMissing,
                    amountUsersCompleteWeek: overviewStats.amountUsersCompleteWeek,
                    amountUsersMissingWeek: overviewStats.amountUsersMissingWeek,
                    amountUsersComplete3Day: overviewStats.amountUsersComplete3Day,
                    amountUsersMissing3Day: overviewStats.amountUsersMissing3Day,
                    lastEncounter: overviewStats.lastEncounter,
                    data: []
                });
            })
            .catch(error => {
              //console.log("Error occurred while fetching overview stats:", error);
              // Handle the error if needed
            });
        } else {
          const myId = window.localStorage.getItem("id");
          getAllMy(myId)
            .then(response => {
                const overviewStats = response; // Assuming the response object has an 'items' property
                //console.log("DATA RETURNED: ", overviewStats);
                this.setState({
                    overviewStats: overviewStats,
                    totalNoOfParticipantsWithEncounters: overviewStats.totalNoOfParticipantsWithEncounters,
                    totalNoOfParticipants: overviewStats.totalNoOfParticipants,
                    totalNoOfEncounters: overviewStats.totalNoOfEncounters,
                    totalNoOfPartialEncounters: overviewStats.totalNoOfPartialEncounters,
                    totalNoOfCompleteEncounters: overviewStats.totalNoOfCompleteEncounters,
                    totalNoOfMissingEncounters: overviewStats.totalNoOfMissingEncounters,
                    lastWeekPartial: overviewStats.lastWeekPartial,
                    lastWeekComplete: overviewStats.lastWeekComplete,
                    lastWeekMissing: overviewStats.lastWeekMissing,
                    lastMonthPartial: overviewStats.lastMonthPartial,
                    lastMonthComplete: overviewStats.lastMonthComplete,
                    lastMonthMissing: overviewStats.lastMonthMissing,
                    amountUsersCompleteWeek: overviewStats.amountUsersCompleteWeek,
                    amountUsersMissingWeek: overviewStats.amountUsersMissingWeek,
                    amountUsersComplete3Day: overviewStats.amountUsersComplete3Day,
                    amountUsersMissing3Day: overviewStats.amountUsersMissing3Day,
                    lastEncounter: overviewStats.lastEncounter,
                    data: []
                });
            })
            .catch(error => {
              //console.log("Error occurred while fetching overview stats:", error);
              // Handle the error if needed
            });
        }
      }

    render() {
        const { overviewStats, overviewStatsLoading } = this.props;
        const dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour:'numeric', minute:'numeric' };

        //console.log("Dashboard 00: ", this.props);
        //console.log("Dashboard 01: ", overviewStats);
        //console.log("Dashboard 012: ", this.state.overviewStats);

        if (overviewStatsLoading) {
            return <div>  Loading...</div>; // Render loading indicator
          }
    
        if (overviewStats != undefined && overviewStats != null) {
            this.state.totalNoOfParticipantsWithEncounters= overviewStats.totalNoOfParticipantsWithEncounters;
            this.state.totalNoOfParticipants= overviewStats.totalNoOfParticipants;
            this.state.totalNoOfEncounters= overviewStats.totalNoOfEncounters;
            this.state.totalNoOfPartialEncounters= overviewStats.totalNoOfPartialEncounters;
            this.state.totalNoOfCompleteEncounters= overviewStats.totalNoOfCompleteEncounters;
            this.state.totalNoOfMissingEncounters= overviewStats.totalNoOfMissingEncounters

            this.state.lastWeekPartial= overviewStats.lastWeekPartial
            this.state.lastWeekComplete= overviewStats.lastWeekComplete
            this.state.lastWeekMissing= overviewStats.lastWeekMissing
            this.state.lastMonthPartial= overviewStats.lastMonthPartial
            this.state.lastMonthComplete= overviewStats.lastMonthComplete
            this.state.lastMonthMissing= overviewStats.lastMonthMissing

            this.state.amountUsersCompleteWeek= overviewStats.amountUsersCompleteWeek
            this.state.amountUsersMissingWeek= overviewStats.amountUsersMissingWeek
            this.state.amountUsersComplete3Day= overviewStats.amountUsersComplete3Day
            this.state.amountUsersMissing3Day= overviewStats.amountUsersMissing3Day
            if(overviewStats.lastEncounter != null){
                var lastEnc = new Date(overviewStats.lastEncounter);
                this.state.lastEncounter= lastEnc.toLocaleDateString("en-UK", dateOptions);
            } else {
                this.state.lastEncounter= "";
            }
        }

        var totalWeek = this.state.lastWeekPartial + this.state.lastWeekComplete + this.state.lastWeekMissing;
        var divisor = totalWeek;
        if(totalWeek === 0){
           divisor = 1
        };
        var averageWeek = (this.state.lastWeekPartial * 0.5 + this.state.lastWeekComplete)/divisor*100;
        var prcEngagementWeek = averageWeek.toFixed(1) + "%";
        var strEngagementWeek = this.state.lastWeekComplete + "-" + this.state.lastWeekPartial + "-" + this.state.lastWeekMissing + " / " + totalWeek;

        var totalMonth = this.state.lastMonthPartial + this.state.lastMonthComplete + this.state.lastMonthMissing;
        divisor = totalMonth;
        if(totalMonth === 0){
           divisor = 1
        };
        var averageMonth = (this.state.lastMonthPartial * 0.5 + this.state.lastMonthComplete)/divisor*100;
        var prcEngagementMonth = averageMonth.toFixed(1) + "%";
        var strEngagementMonth = this.state.lastMonthComplete + "-" + this.state.lastMonthPartial + "-" + this.state.lastMonthMissing + " / " + totalMonth;

        //console.log("Dashboard 02 ", overviewStats);
        //console.log("Dashboard 03 ", overviewStatsLoading);

        return (

            <>
                <PanelHeader size="sm" />
                <div className="content">
                    <Row>
                        <Col xs={12} md={12}>
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h4">Dashboard</CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form action="#" method="#">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <CardTitle tag="h6">Encounters</CardTitle>
                                                <Row>
                                                    <Col xs={3} md={3}>
                                                        <label className="color_careloop">Total: </label>
                                                        <div className="overview-stat-item">{this.state.totalNoOfEncounters}</div>
                                                    </Col>
                                                    <Col xs={3} md={3}>
                                                        <label className="color_careloop">Completed: </label>
                                                        <div className="overview-stat-item">{this.state.totalNoOfCompleteEncounters}</div>
                                                    </Col>
                                                    <Col xs={3} md={3}>
                                                        <label className="color_careloop">Partial: </label>
                                                        <div className="overview-stat-item">{this.state.totalNoOfPartialEncounters}</div>
                                                    </Col>
                                                    <Col xs={3} md={3}>
                                                        <label className="color_careloop">Missed: </label>
                                                        <div className="overview-stat-item">{this.state.totalNoOfMissingEncounters}</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <br/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <label className="color_careloop">Last Encounter: </label>
                                                        <div className="overview-stat-item">{this.state.lastEncounter}</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <br/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={3} md={3}>
                                                        <label className="color_careloop">Percentage of Engagement Last Week: </label>
                                                        <div className="overview-stat-item">{prcEngagementWeek}</div>
                                                    </Col>
                                                    <Col xs={3} md={3}>
                                                        <label className="color_careloop">Completed-Partial-Missing/Total Last Week: </label>
                                                        <div className="overview-stat-item">{strEngagementWeek}</div>
                                                    </Col>
                                                    <Col xs={3} md={3}>
                                                        <label className="color_careloop">Percentage of Engagement Last Month: </label>
                                                        <div className="overview-stat-item">{prcEngagementMonth}</div>
                                                    </Col>
                                                    <Col xs={3} md={3}>
                                                        <label className="color_careloop">Completed-Partial-Missing/Total Last Month: </label>
                                                        <div className="overview-stat-item">{strEngagementMonth}</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <br/><br/>
                                                    </Col>
                                                </Row>
                                                <CardTitle tag="h6">Participants</CardTitle>
                                                <Row>
                                                    <Col xs={4} md={4}>
                                                        <label className="color_careloop">Total: </label>
                                                        <div className="overview-stat-item">{this.state.totalNoOfParticipants}</div>
                                                    </Col>
                                                    <Col xs={4} md={4}>
                                                        <label className="color_careloop">With Encounters: </label>
                                                        <div className="overview-stat-item">{this.state.totalNoOfParticipantsWithEncounters}</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <br/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={3} md={3}>
                                                        <label className="color_careloop">With Completed Encounters last 3 days: </label>
                                                        <div className="overview-stat-item">{this.state.amountUsersComplete3Day}</div>
                                                    </Col>
                                                    <Col xs={3} md={3}>
                                                        <label className="color_careloop">With Missed Encounters last 3 days: </label>
                                                        <div className="overview-stat-item">{this.state.amountUsersMissing3Day}</div>
                                                    </Col>
                                                    <Col xs={3} md={3}>
                                                        <label className="color_careloop">With Completed Encounters last Week: </label>
                                                        <div className="overview-stat-item">{this.state.amountUsersCompleteWeek}</div>
                                                    </Col>
                                                    <Col xs={3} md={3}>
                                                        <label className="color_careloop">With Missed Encounters last Week: </label>
                                                        <div className="overview-stat-item">{this.state.amountUsersMissingWeek}</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <br/><br/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

function mapState(state) {
    const { overviewStats, overviewStatsLoading } = state.overviewStats;
    //console.log("mapState: ", state.overviewStats);
    return { overviewStats };
}

const actionCreators = {
    getAll: overviewStatsActions.getAll,
    getAllMy: overviewStatsActions.getAllMy
};

const connectedOverviewStats = connect(mapState, actionCreators)(OverviewStats);
export { connectedOverviewStats as OverviewStats };

/*
const mapStateToProps = (state) => ({
    overviewStats: state.overviewStats,
    overviewStatsLoading: state.overviewStatsLoading,
  });
  
  const mapDispatchToProps = {
    getAll: overviewStatsActions.getAll,
    getAllMy: overviewStatsActions.getAllMy,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(OverviewStats); */