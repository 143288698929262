import React, { Component } from "react"; 
import ReactTable from "react-table";
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardTitle, Row, Col, Button  } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

import { isConfigReady, isStudyStarted, updateInstallStatus, configNotReadyErrors } from '../../helpers';
import { participantsActions } from '../../actions';  

class Participants extends Component {
  constructor(props) {
    super(props);
    //console.log("props.location.pathname: ", props.location.pathname)
    var myId = 0;

    if(props.location.pathname === "/admin/participants"){
      this.props.getAll();
    }else{
      myId = window.localStorage.getItem("id");
      this.props.getAllMy(myId);
    }

    updateInstallStatus();

    this.state = {
      data: [],
      myID: myId
    };
  }

  render() {
    const { participants, participantsLoading } = this.props;
    const dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour:'numeric', minute:'numeric' };

    if (participants && participants.length > 0) {
      //console.log("Participants: ", participants);

      var dataItems = participants.map((el) => {
        var lastEncounter = new Date(el.lastEncounter);
        var firstDate = new Date('1970/02/02');
        
        var showDate = "";
        var dayWeek = "";
        if(lastEncounter<firstDate){
          showDate = "";
        } else {
          showDate = lastEncounter.toISOString()
          dayWeek = lastEncounter.toLocaleDateString("en-UK", dateOptions);
          showDate = showDate.substring(0,16).replace("T", "  -  "); //.replace(".000Z", "  ")
          showDate += " - " + dayWeek.substring(0,3);
        }

        var totalWeek = el.partialWeek + el.completeWeek + el.missingWeek;
        var divisor = totalWeek;
        if(totalWeek === 0){
          divisor = 1
        };
        var averageWeek = (el.partialWeek * 0.5 + el.completeWeek)/divisor*100;
        var strEngagementWeek = averageWeek.toFixed(1) + "% / " + el.completeWeek + "-" + el.partialWeek + "-" + el.missingWeek + " / " + totalWeek;
          
        var totalMonth = el.partialMonth + el.completeMonth + el.missingMonth;
        divisor = totalMonth;
        if(totalMonth === 0){
          divisor = 1
        };
        var averageMonth = (el.partialMonth * 0.5 + el.completeMonth)/divisor*100;
        var strEngagementMonth = averageMonth.toFixed(1) + "% / " + el.completeMonth + "-" +el.partialMonth + "-" +el.missingMonth + " / " +totalMonth;

        //console.log("participant: ", el);

        //var last7DaysEPDSScores = el.epds; //"0-0-0-0-0-0-0";
        //var last7DaysDHSScores = el.dsh; //"0-0-0-0-0-0-0";

        return {
            id: el.participant.id,
            district: el.participant.user.login,
            provider: el.participant.provider.user.login,
            partialEncounters: el.partialEncounters,
            completeEncounters: el.completeEncounters,
            missingEncounters: el.missingEncounters,
            engagementWeek: strEngagementWeek,
            engagementMonth: strEngagementMonth,
            weekEPDSScores: el.epds, //last7DaysEPDSScores,
            weekDHSScores: el.dsh, //last7DaysDHSScores,
            //"<span style='visibility: hidden;'>" + lastEncounter.toISOString() + "</span>"+ lastEncounter.toLocaleDateString("en-UK", dateOptions),
            lastEncounter: showDate,
            completed: el.participant.completed === true ? "true" : "false",
            actions: (
              <div className="actions-right">
                <Button className="btn-icon btn-round" color="info" size="sm"
                  onClick={() => {
                    this.props.history.push(`/admin/participants/${el.id}/summary`);
                  }}>
                  <i className="fa fa-info" />
                </Button>{"  "}

                <Button
                  onClick={() => {
                    //console.log(el.participant);
                    this.props.history.push({
                      pathname: `/admin/participants/${el.id}/edit`,
                      state: { theParticipant: el.participant}
                    });
                  }}
                  className="btn-icon btn-round"
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>
              </div>
            )
          };
      });
    }

    const askConfirmationStartStudy = () => {
        this.setState({ alert: null })
        this.setState({
            alert: (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, start it!"
                confirmBtnBsStyle="danger"
                title="Start the Study?"
                style={{ display: "block", marginTop: "100px" }}
                onConfirm={() => this.props.history.push("/admin/participants/0/edit")}
                onCancel={() => this.setState({ alert: null })}
                //focusCancelBtn
              >
                Once the first service user is created you will not be able to change any configuration anymore!
              </SweetAlert>
            )
        });
    }

    var showConfigurationErrors = () => {
        this.setState({ alert: null })
        this.setState({
            alert: (
              <SweetAlert
                  error
                  style={{ display: "block", marginTop: "100px" }}
                  title="Study is not ready to be started. There are errors in the configuration!"
                  onConfirm={() => this.hideAlert()}
                  onCancel={() => this.hideAlert()}
                  confirmBtnBsStyle="danger"
              >
                  {configNotReadyErrors()}
            </SweetAlert>
            )
        });
    }

    const addParticipant = () => {
      updateInstallStatus();
      var studyStarted = isStudyStarted();
      var configReady = isConfigReady();
      //console.log("Participants List isStudyStarted/isConfigReady: ", this.state.isStudyStarted, this.state.isConfigReady);

      if(studyStarted){
        this.props.history.push("/admin/participants/0/edit");
      } else {
        if(configReady){
          askConfirmationStartStudy();
        } else {
          showConfigurationErrors();
        }
      };
    }

    var buttonAdd = <Button color="info" className="btn-round btn-icon pull-right"
                        onClick={() => { 
                          addParticipant();
                        }}>
                        <i className="now-ui-icons ui-1_simple-add" />
                      </Button>;

    var title = "Service Users";
    if(this.state.myID > 0) {
      title = "My Participants";
    }

    function filterCaseInsensitive(filter, row) {
      const id = filter.pivotId || filter.id;
      return (
          row[id] !== undefined ?
              String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
          :
              true
      );
    }

    return (
      <>
        {this.state.alert}
        <PanelHeader size="sm" />
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <Card>
                <CardHeader>
                  {buttonAdd}
                  <CardTitle tag="h4">{title}</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={dataItems}
                    filterable
                    defaultFilterMethod={(filter, row) => filterCaseInsensitive(filter, row) }
                    columns={[
                      {
                        Header: "User Id",
                        accessor: "district",
                        maxWidth: 500
                      },
                      {
                        Header: "Provider",
                        accessor: "provider",
                        maxWidth: 500
                      },
                      {
                        Header: "Completed",
                        accessor: "completed",
                        maxWidth: 150 
                      },
                      /*{
                        Header: "# Completed",
                        accessor: "completeEncounters",
                        maxWidth: 100,
                        style: {"textAlign":"right"}
                      },
                      {
                        Header: "# Partial",
                        accessor: "partialEncounters",
                        maxWidth: 100,
                        style: {"textAlign":"right"}
                      },
                      {
                        Header: "# Missing",
                        accessor: "missingEncounters",
                        maxWidth: 100,
                        style: {"textAlign":"right"}
                      },*/
                      {
                        Header: "Monthly Engagement",
                        accessor: "engagementMonth",
                        maxWidth: 200,
                        style: {"textAlign":"right"}
                      },
                      {
                        Header: "Last 7 Days EPDS scores",
                        accessor: "weekEPDSScores",
                        maxWidth: 200,
                        style: {"textAlign":"right"}
                      },
                      {
                        Header: "Last 7 Days DHS scores",
                        accessor: "weekDHSScores",
                        maxWidth: 200,
                        style: {"textAlign":"right"}
                      },
                      /*{
                        Header: "Weekly Engagement",
                        accessor: "engagementWeek",
                        maxWidth: 200,
                        style: {"textAlign":"right"}
                      },*/
                      {
                        Header: "Last Questionnaire",
                        accessor: "lastEncounter",
                        maxWidth: 350,
                        style: {"textAlign":"right"}
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        maxWidth: 150,
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationBottom={true}
                    className="-striped -highlight"
                    loading={participantsLoading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

function mapState(state) {
  const { participants, participantsLoading } = state.participants;
  return { participants, participantsLoading };
}

const actionCreators = {
  getAll: participantsActions.getAll,
  getAllMy: participantsActions.getAllMy
};

const connectedParticipants = connect(mapState, actionCreators)(Participants);
export { connectedParticipants as Participants }; 